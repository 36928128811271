<template>
  <div>
    <el-form
      ref="nodeForm"
      :rules="rules"
      label-position="top"
      label-width="100px"
      :model="nodeToBind"
      v-loading="nodeLoading"
      :hide-required-asterisk="false"
    >
      <el-row type="flex">
        <el-col :span="24">
          <el-form-item
            prop="node_name"
            :label="__('Name')"
            class="is-required"
          >
            <el-input v-model="nodeToBind.node_name"></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-tabs v-model="activeTab" class="tabs">
        <el-tab-pane :label="__('Message')" name="message">
          <el-row
            type="flex"
            style="padding-bottom: 10px"
            v-if="this.isCardAndCarouselFeatureEnabled"
          >
            <el-col :span="24">
              <el-form-item
                prop="messaging_node.data.message_format"
                :label="__('Message Format')"
                style="margin-top: 10px"
              >
                <el-radio-group
                  v-model="messageFormat"
                  style="margin-bottom: 10px;"
                >
                  <el-radio-button :label="MESSAGE_FORMAT_PLAIN_TEXT">{{
                    __("Plain Text")
                  }}</el-radio-button>
                  <el-radio-button
                    :label="MESSAGE_FORMAT_CARD"
                    v-if="nodeSupportCardsCarousel"
                    ref="cardCarouselTabSelector"
                    >{{ __("Card") }}</el-radio-button
                  >
                  <el-radio-button :label="MESSAGE_FORMAT_RICH_TEXT">{{
                    __("Rich Text")
                  }}</el-radio-button>
                </el-radio-group>
              </el-form-item>
              <div v-if="messageFormat === MESSAGE_FORMAT_PLAIN_TEXT">
                <el-form-item
                  style="position: relative"
                  :show-message="!smsContent"
                  prop="messaging_node.data.sms_content"
                  :label="__('Messaging Content')"
                  class="is-required"
                >
                  <el-form-item :error="hasInvalidVariable(smsContent)">
                    <input-variable-popper
                      force-reinitialize
                      v-model="smsContent"
                      :include-secure-variables="false"
                      :placeholder="__('Add text message to send')"
                    />
                  </el-form-item>
                </el-form-item>
                <SMSCounter v-model="smsContent" />
              </div>
              <div v-else-if="messageFormat === MESSAGE_FORMAT_CARD">
                <CardAndCarousel
                  v-model="cardModel"
                  :gotoOptions="generateGotoOptionsForPostbackButton"
                  prop="messaging_node.data.messaging_node_card.data"
                />
              </div>
              <div v-else-if="messageFormat === MESSAGE_FORMAT_RICH_TEXT">
                <!--
                TODO: When implementing markdown, make sure that when the node
                is send SMS (or task type is SMS task), then the type remains
                as plain-text or that special consideration is discussed.
                -->
                <el-row type="flex">
                  <el-col :span="24">
                    <el-form-item
                      style="position: relative"
                      :prop="richTextMessageContentProps"
                      :label="__('Message Content')"
                      class="is-required"
                    >
                      <el-form-item :error="hasInvalidVariable(markdown)">
                        <input-variable-popper
                          force-reinitialize
                          v-model="richTextContent"
                          :include-secure-variables="false"
                          :is-markdown-editor="true"
                          :placeholder="__('Add text message to send')"
                        />
                      </el-form-item>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row type="flex">
                  <el-col :span="24">
                    <el-form-item
                      prop="messaging_node.data.messaging_node_attachment.data"
                      :label="__('Media')"
                    >
                      <messaging-node-attachment v-model="messageAttachments" />
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row type="flex" v-if="shouldShowMessageButtons">
                  <el-col :span="24">
                    <el-form-item
                      prop="messaging_node.data.messaging_node_button.data"
                      :label="__('Buttons')"
                    >
                      <messaging-node-buttons
                        v-model="messagingButtons"
                        :gotoOptions="generateGotoOptions"
                        :gotoOptionsForPostback="
                          generateGotoOptionsForPostbackButton
                        "
                        @removed-msg-btn="handleMessageBtnRemoved($event)"
                        style="width: 100%;"
                        :nodeType="nodeToBind.node_type"
                      />
                    </el-form-item>
                  </el-col>
                </el-row>
              </div>
            </el-col>
          </el-row>
          <el-row type="flex" style="padding-bottom: 10px" v-else>
            <el-col :span="24">
              <el-form-item
                style="position: relative"
                :show-message="!smsContent"
                prop="messaging_node.data.sms_content"
                :label="__('Messaging Content')"
                class="is-required"
              >
                <el-form-item :error="hasInvalidVariable(smsContent)">
                  <input-variable-popper
                    force-reinitialize
                    v-model="smsContent"
                    :include-secure-variables="false"
                    :placeholder="__('Add text message to send')"
                  />
                </el-form-item>
              </el-form-item>
              <SMSCounter v-model="smsContent" />
            </el-col>
          </el-row>

          <el-row type="flex">
            <el-col :span="24">
              <template v-if="isNodeTypeSendSMS">
                <el-form-item
                  v-if="this.displaySmsProfile"
                  prop="messaging_node.data.sms_profile_id"
                  :label="__('SMS Profile')"
                  class="is-required"
                >
                  <el-select
                    v-loading="smsProfilesLoading"
                    v-model="nodeToBind.messaging_node.data.sms_profile_id"
                    @change="smsProfileChange"
                    default-first-option
                    filterable
                    clearable
                    style="width: 100%"
                  >
                    <el-option
                      v-for="smsProfile in smsProfiles"
                      :key="smsProfile.sms_profile_id"
                      :label="smsProfile.sms_profile_name"
                      :value="smsProfile.sms_profile_id"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item
                  prop="messaging_node.data.phone_number"
                  :label="__('Messaging Receiver')"
                  class="is-required"
                >
                  <div class="tooltips">
                    {{ __("Enter a phone number using a") }}
                    <b>{{ __("variable") }}</b>
                    {{ __("or just using number format like") }}
                    <b>9324234234</b> {{ __("or using combination of both.") }}
                  </div>

                  <input-variable-popper
                    v-model="nodeToBind.messaging_node.data.phone_number"
                    :is-text-area="false"
                    :include-prepend="true"
                    prepend-string="+"
                    v-permit="allowedRegex"
                    :include-secure-variables="false"
                  />
                </el-form-item>
              </template>
            </el-col>
          </el-row>
        </el-tab-pane>
        <el-tab-pane
          :label="__('Advanced')"
          name="advanced"
          v-if="this.task_type === 'chatbot'"
          style="padding-top: 10px"
        >
          <el-row type="flex">
            <el-col :span="24">
              <el-form-item
                prop="messaging_node.data.message_json"
                :label="__('Message JSON')"
              >
                <div class="tooltips msgJsonTip">
                  {{ __("Click for ") }}
                  <a
                    href="https://docs.studioportal.io/Content/studio-nodes/topics/conversation-advanced.htm"
                    target="_blank"
                    >documentation</a
                  >
                  {{ __(" on the JSON format.") }}
                </div>
                <input-variable-popper
                  force-reinitialize
                  v-model="messageJson"
                  :placeholder="__('Add Json message to send')"
                  :include-secure-variables="false"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row type="flex" v-if="isNodeTypeConversationSMS">
            <el-col :span="24">
              <el-form-item
                prop="messaging_node.data.incoming_json"
                :label="__('Incoming JSON Format')"
              >
                <el-input
                  type="textarea"
                  :rows="4"
                  :placeholder="__('Define sample Json to receive user data')"
                  v-model="incomingJson"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-tab-pane>
        <el-tab-pane
          :label="__('Rich Media')"
          name="attachment"
          v-if="displayRichMediaTab"
          style="padding-top: 10px"
        >
          <el-row type="flex">
            <el-col :span="24">
              <el-form-item
                prop="messaging_node.data.messaging_node_attachment.data"
                :label="__('Message Attachments')"
                class="is-required"
              >
                <messaging-node-attachment v-model="messageAttachments" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row type="flex" v-if="shouldShowMessageButtons">
            <el-col :span="24">
              <el-form-item
                prop="messaging_node.data.messaging_node_button.data"
                :label="__('Message Buttons')"
              >
                <messaging-node-buttons
                  v-model="messagingButtons"
                  :gotoOptions="generateGotoOptions"
                  :gotoOptionsForPostback="generateGotoOptionsForPostbackButton"
                  @removed-msg-btn="handleMessageBtnRemoved($event)"
                  style="width: 100%;"
                  :nodeType="nodeToBind.node_type"
                />
              </el-form-item>
            </el-col>
          </el-row>
        </el-tab-pane>
        <el-tab-pane
          :label="__('Response')"
          name="response"
          v-if="isNodeTypeConversationSMS"
        >
          <el-scrollbar :native="false">
            <template v-if="isNodeTypeConversationSMS">
              <div class="tabPane">
                <el-form-item
                  prop="messaging_node.data.variable_rules.data"
                  :label="__('Extract Words')"
                >
                  <extract-words
                    v-model="wordRules"
                    :variables="singleValuedAndSecureVariables"
                    source="conversation"
                  />
                </el-form-item>

                <el-form-item
                  prop="messaging_node.data.keyword_conditions.data"
                  :label="__('Match Keywords')"
                >
                  <match-keywords
                    v-model="keywordConditions"
                    :errors="messageBtnKeywordErrors"
                    :gotoOptions="generateGotoOptions"
                  />
                </el-form-item>

                <el-row type="flex">
                  <el-col :span="24">
                    <el-form-item
                      prop="otherwise"
                      :label="__('Otherwise Goto Node')"
                    >
                      <other-wise
                        v-model="otherwiseCondition"
                        :goto-options="generateGotoOptions"
                      />
                    </el-form-item>
                  </el-col>
                </el-row>
              </div>
            </template>
          </el-scrollbar>
        </el-tab-pane>

        <el-tab-pane
          :label="__('Response')"
          name="response"
          v-if="isNodeTypeSendSMS && (isTaskTypeVoice || isTaskTypeChatBot)"
        >
          <el-scrollbar :native="false">
            <div class="tabPane" v-loading="smsProfilesLoading">
              <return-values
                :key="componentKey"
                :return-value="smsProfileReturnValue"
                @update-return-value="handleUpdateSmsProfileReturnValue"
              ></return-values>
            </div>
          </el-scrollbar>
        </el-tab-pane>
        <el-tab-pane
          :label="__('Delivery Notification')"
          name="delivery_notification"
          v-if="!isTaskTypeChatBot"
        >
          <el-scrollbar :native="false">
            <div class="tabPane">
              <el-row type="flex">
                <el-col :span="18">
                  <el-form-item
                    :show-message="false"
                    prop="messaging_node.data.web_hook_url"
                    :label="__('Web Hook URL')"
                  >
                    <el-form-item :error="getErrorStringForURL">
                      <input-variable-popper
                        key="web_hook_url"
                        v-model="nodeToBind.messaging_node.data.web_hook_url"
                        :is-text-area="false"
                        :include-secure-variables="false"
                      />
                    </el-form-item>
                  </el-form-item>
                </el-col>
              </el-row>

              <el-form-item
                :show-message="false"
                prop="messaging_node.data.query_params_json"
                :label="__('Query Params')"
              >
                <query-params
                  v-model="queryParams"
                  :use-default-field="false"
                  :use-default-key-field="false"
                  :use-secure-variables="false"
                  @invalid-variables="
                    updateInvalidVariableMap($event)('query_params')
                  "
                />
              </el-form-item>
            </div>
          </el-scrollbar>
        </el-tab-pane>
      </el-tabs>
      <el-row
        type="flex"
        v-if="showPostSendAction"
        ref="postSendActionContainer"
      >
        <el-col :span="24">
          <el-checkbox
            v-model="checkedPostSendAction"
            @change="handleCheckedPostSendAction"
            class="postSendActionCheckBox"
          >
            <span style="font-size: 16px">Post-Sending Action</span>
          </el-checkbox>

          <el-form-item
            style="position: relative"
            v-if="checkedPostSendAction"
            id="postSendActionOptions"
            prop="messaging_node.data.message_action_option"
          >
            <el-row>
              <span>
                <!-- eslint-disable-next-line -->
                {{ __("Select an action that will happen after message has been sent.") }}
              </span>
            </el-row>
            <el-row>
              <el-radio-group v-model="selectedAction">
                <el-radio-button label="terminate">
                  {{ __("Close Chat") }}
                </el-radio-button>
                <el-radio-button label="agentTransfer">
                  {{ __("Transfer to Live Agent") }}
                </el-radio-button>
              </el-radio-group>
            </el-row>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import BaseNode from "@/views/build/callflow/components/node-type-forms/BaseNode";
import QueryParams from "@/views/build/callflow/components/node-type-forms/components/QueryParams";
import MatchKeywords from "@/views/build/callflow/components/node-type-forms/components/MatchKeywords";
import ExtractWords from "@/views/build/callflow/components/node-type-forms/components/ExtractWords";
import OtherWise from "@/views/build/callflow/components/node-type-forms/components/OtherWise";
import SMSCounter from "@/views/build/callflow/components/node-type-forms/components/SMSCounter";
import _ from "lodash";
import InputVariablePopper from "@/views/build/callflow/components/node-type-forms/components/InputVariablePopper";
import MessagingNodeAttachment from "@/views/build/callflow/components/node-type-forms/components/MessagingNodeAttachment";
import MessagingNodeButtons from "@/views/build/callflow/components/node-type-forms/components/MessagingNodeButtons";
import ReturnValues from "@/views/build/callflow/components/node-type-forms/components/ReturnValues";
import CardAndCarousel from "./carousel";
import {
  DEFAULT_MESSAGE_FORMAT,
  MESSAGE_FORMAT_PLAIN_TEXT,
  MESSAGE_FORMAT_CARD,
  MESSAGE_FORMAT_RICH_TEXT
} from "@/constants/messagingNodeFormat";
import {
  LINK_BUTTON,
  POSTBACK_BUTTON,
  PROACTIVE_POSTBACK_BUTTON,
  REPLY_BUTTON,
  LOCATION_BUTTON
} from "@/constants/messagingNodeButtons";
import {
  filterRowsIfEveryKeyValueIsAbsent,
  filterRowsIfSomeKeyValueIsAbsent,
  getSubKeyObject
} from "@/utils/collection";
import { NODE_CATEGORIES, NODE_TYPES } from "@/constants/nodes";
import { mapActions, mapGetters, mapState } from "vuex";
import { variableRulesValidation } from "@/utils/formValidationRules";
import {
  validateJsonUsingSchema,
  getJsonSchemaErrorString
} from "@/utils/jsonSchemaValidation";
import { numberOrVariable } from "@/utils/regex";
import { validPhoneNumber } from "@/utils/validate";
import { CONVERSATION_NODE_MESSAGE_JSON_SCHEMA } from "@/constants/jsonSchemas";

export default {
  name: "BaseMessagingNode",
  mixins: [BaseNode],
  components: {
    ExtractWords,
    QueryParams,
    MatchKeywords,
    OtherWise,
    SMSCounter,
    InputVariablePopper,
    MessagingNodeAttachment,
    MessagingNodeButtons,
    ReturnValues,
    CardAndCarousel
  },
  data() {
    const validateOtherwiseNode = (rule, value, callback) => {
      const otherwiseNode = _.find(
        this.nodeToBind.messaging_node.data.keyword_conditions.data,
        { keyword: "Otherwise" }
      );
      if (!otherwiseNode) {
        callback(__("Otherwise node cannot be empty"));
      } else {
        if (otherwiseNode.node_id === -1 && !otherwiseNode.node_name) {
          callback(__("Otherwise node cannot be empty"));
        } else if (otherwiseNode.node_id === -1) {
          !_.map(this.getValidNodes, "node_name").includes(
            otherwiseNode.node_name
          )
            ? callback()
            : callback(__("Node exists"));
        } else {
          callback();
        }
      }
    };

    const validateAttachments = (rule, value, callback) => {
      if (!_.isEmpty(value)) {
        // if _.some return true, it means its invalid
        _.some(value, attachment => {
          // if all the relevant fields are empty, dont consider it to be invalid
          if (!attachment.attachment_type && !attachment.file_url) {
            callback();
          } else if (attachment.attachment_type && attachment.file_url) {
            if (attachment.validate_audio === false) {
              callback(
                // eslint-disable-next-line
                __("The variable selected is invalid. The 'Audio' variable can only be chosen when the attachment type is set to 'Audio'.")
              );
            } else {
              callback();
            }
          } else if (!attachment.attachment_type || !attachment.file_url) {
            // if something is missing
            callback(__("Missing attachment type or file url"));
          }
        });
      } else {
        callback();
      }
    };

    const validateVariableRules = (rule, value, callback) => {
      let isValid = variableRulesValidation(rule, value, this);
      isValid
        ? callback()
        : callback(__("word rules configuration incomplete"));
    };

    const validatePhoneNumber = (rule, value, callback) => {
      if (!value) {
        callback(new Error(__("Phone Number cannot be empty")));
      } else if (!validPhoneNumber(value)) {
        callback(new Error(__("Please enter a valid phone number")));
      } else {
        callback();
      }
    };
    const validateQueryParams = (rule, value, cb) => {
      if (this.invalidVariablesMap["query_params"]) {
        cb(
          // eslint-disable-next-line
            __("Invalid variables found in Query Params (Delivery Notification)")
        );
      } else {
        cb();
      }
    };

    const validateUrl = (rule, value, cb) => {
      if (!value) {
        cb();
      } else if (
        this.hasInvalidVariable(
          this.nodeToBind.messaging_node.data.web_hook_url
        )
      ) {
        cb(
          // eslint-disable-next-line
            __("Invalid variables found in Webhook URL (Delivery Notification)")
        );
      } else {
        cb();
      }
    };

    const validateSmsContent = (rule, value, cb) => {
      const plainTextSmsContentInUse =
        this.messageFormat === MESSAGE_FORMAT_PLAIN_TEXT ||
        this.isNodeTypeSendSMS;
      if (plainTextSmsContentInUse) {
        if (!value) {
          cb(__("Messaging content cannot be empty"));
        } else if (this.hasInvalidVariable(value)) {
          cb(__("Invalid variables found in Messaging content"));
        } else {
          cb();
        }
      } else {
        cb();
      }
    };
    const validateMessageJson = (rule, value, cb) => {
      if (!value) {
        cb();
      } else if (this.hasInvalidVariable(value)) {
        cb(__("Invalid variables found in Json content"));
      } else {
        try {
          let parsedJson = JSON.parse(value);
          // only perform schema validation for message json field based on the digital messaging api schema
          if (_.last(_.split(_.get(rule, "field"), ".")) === "message_json") {
            let { valid, errors } = validateJsonUsingSchema(
              parsedJson,
              CONVERSATION_NODE_MESSAGE_JSON_SCHEMA
            );
            if (!valid && errors.length > 0) {
              cb(getJsonSchemaErrorString(errors));
            }
          }
        } catch (e) {
          if (this.isValueSingleVariable(value)) {
            cb();
            return;
          }
          cb(__("JSON format is invalid"));
          return;
        }
        cb();
      }
    };
    const smsProfileValidator = (rule, value, cb) => {
      if (this.displaySmsProfile) {
        if (!value) {
          cb(__("SMS Profile cannot be empty"));
        } else {
          cb();
        }
      } else {
        cb();
      }
    };

    const markdownValidator = (rule, value, cb) => {
      if (this.messageFormat === MESSAGE_FORMAT_RICH_TEXT) {
        if (!value) {
          cb(__("Message Content cannot be empty"));
        } else {
          cb();
        }
      } else {
        cb();
      }
    };

    /**
     * Validating the rich media button combinations.
     * @param rule
     * @param buttons
     * @param cb
     */
    const validateButtonCombinations = (rule, buttons, cb) => {
      const buttonsInUse = _.map(buttons, "button_type");

      if (
        (_.includes(buttonsInUse, POSTBACK_BUTTON) ||
          _.includes(buttonsInUse, PROACTIVE_POSTBACK_BUTTON)) &&
        _.some(
          buttonsInUse,
          button =>
            button === LOCATION_BUTTON ||
            button === REPLY_BUTTON ||
            button === LINK_BUTTON
        )
      ) {
        cb(
          // eslint-disable-next-line
          __("Invalid combination: Postback Button not allowed with other button types")
        );
        return true;
      }

      if (
        _.includes(buttonsInUse, REPLY_BUTTON) &&
        _.some(buttonsInUse, button => button && button !== REPLY_BUTTON)
      ) {
        cb(
          // eslint-disable-next-line
          __("Invalid combination: Reply Button not allowed with other button types")
        );
        return true;
      }

      if (
        _.includes(buttonsInUse, LOCATION_BUTTON) &&
        _.some(buttonsInUse, button => button === LINK_BUTTON)
      ) {
        cb(
          // eslint-disable-next-line
          __("Invalid combination: Location Button not allowed with Link button")
        );
        return true;
      }

      if (
        _.includes(buttonsInUse, LOCATION_BUTTON) &&
        _.filter(buttonsInUse, button => button === LOCATION_BUTTON).length > 1
      ) {
        cb(
          // eslint-disable-next-line
          __("Invalid combination: Multiple Location Buttons not allowed")
        );
        return true;
      }

      cb();
    };

    const validateButtons = (rule, value, callback) => {
      if (
        !_.some(this.messagingButtons, function(obj) {
          if (obj.keyword === "" && obj.button_type !== "") {
            callback(__("Button cannot be defined with empty label."));
            return true;
          } else if (obj.keyword !== "" && obj.button_type === "") {
            callback(__("Button type cannot be empty."));
            return true;
          } else if (obj.button_icon && obj.button_icon.length > 1000) {
            callback(__("Icon cannot exceed 1000 characters."));
            return true;
          } else if (
            _.includes(
              [REPLY_BUTTON, POSTBACK_BUTTON, PROACTIVE_POSTBACK_BUTTON],
              obj.button_type
            ) &&
            obj.keyword !== "" &&
            obj.node_name === ""
          ) {
            callback(__("Node cannot be empty."));
            return true;
          } else if (
            obj.button_type === LINK_BUTTON &&
            obj.keyword !== "" &&
            obj.button_url === ""
          ) {
            callback(__("URL cannot be empty."));
            return true;
          }
        })
      )
        callback();
    };

    return {
      MESSAGE_FORMAT_PLAIN_TEXT,
      MESSAGE_FORMAT_CARD,
      MESSAGE_FORMAT_RICH_TEXT,
      rules: {
        messaging_node: {
          data: {
            messaging_node_attachment: {
              data: { validator: validateAttachments, trigger: "none" }
            },
            messaging_node_button: {
              data: [
                { validator: validateButtons, trigger: "none" },
                {
                  validator: validateButtonCombinations,
                  trigger: "none"
                }
              ]
            },
            web_hook_url: {
              validator: validateUrl
            },
            variable_rules: {
              data: { validator: validateVariableRules }
            },
            query_params_json: { validator: validateQueryParams },
            phone_number: [
              {
                validator: validatePhoneNumber,
                trigger: "blur"
              }
            ],
            sms_content: [
              {
                validator: validateSmsContent,
                trigger: "blur"
              }
            ],
            message_json: [
              {
                required: false,
                message: __("Message json structure is not valid"),
                trigger: "blur"
              },
              {
                validator: validateMessageJson
              }
            ],
            incoming_json: [
              {
                required: false,
                message: __("Incoming json structure is not valid"),
                trigger: "blur"
              },
              {
                validator: validateMessageJson
              }
            ],
            sms_profile_id: [
              { validator: smsProfileValidator, trigger: "blur" }
            ],
            message_action_option: [
              {
                required: false,
                message: __("Action cannot be empty"),
                trigger: "change"
              }
            ],
            meta_data: {
              data: {
                markdown_content: [
                  {
                    validator: markdownValidator,
                    trigger: "change"
                  }
                ]
              }
            }
          }
        },
        otherwise: {
          validator: validateOtherwiseNode,
          trigger: "change"
        }
      },
      activeTab: "message",
      allowedRegex: numberOrVariable,
      invalidVariablesMap: {
        query_params: false
      },

      // return value
      componentKey: 0,
      smsProfileReturnValue: [],
      nodeLoading: false,
      isCardAndCarouselFeatureEnabled: false,
      selectedSmsProvider: null,
      selectedSmsProfile: null,
      checkedPostSendAction: false
    };
  },

  computed: {
    ...mapState("smsprofiles", {
      smsProfiles: state => state.smsProfiles,
      smsProfilesLoading: state => state.loading
    }),

    ...mapGetters("smsprofiles", {
      getSmsProfile: "getSmsProfile"
    }),

    ...mapGetters("smsproviders", {
      getSmsProvider: "getSmsProvider"
    }),

    emptyURL() {
      if (!this.nodeToBind.messaging_node.data.web_hook_url) {
        return __("URL cannot be empty");
      }
      return "";
    },

    getErrorStringForURL() {
      return this.hasInvalidVariable(
        _.get(this.nodeToBind, "messaging_node.data.web_hook_url", "")
      );
    },

    smsNodeType() {
      return this.nodeToBind.node_type;
    },
    isNodeTypeSendSMS() {
      return this.smsNodeType === NODE_TYPES.SEND_SMS.NODE_TYPE;
    },
    isNodeTypeReplySMS() {
      return this.smsNodeType === NODE_TYPES.REPLY_SMS.NODE_TYPE;
    },
    isNodeTypeProactiveMessage() {
      return this.smsNodeType === NODE_TYPES.PROACTIVE_MESSAGE.NODE_TYPE;
    },
    isNodeTypeConversationSMS() {
      return this.smsNodeType === NODE_TYPES.CONVERSATION_SMS.NODE_TYPE;
    },
    isTaskTypeVoice() {
      return this.task_type === "voice";
    },

    isTaskTypeChatBot() {
      return this.task_type === "chatbot";
    },

    isTaskTypeWorkflow() {
      return this.task_type === "workflow";
    },
    displaySmsProfile() {
      return (
        this.isTaskTypeVoice ||
        this.isTaskTypeChatBot ||
        this.isTaskTypeWorkflow
      );
    },
    displayRichMediaTab() {
      return !this.isCardAndCarouselFeatureEnabled;
    },
    //used for showing all node options for postback media type
    generateGotoOptionsForPostbackButton() {
      const nodeToUse = !this.isEditing ? this.node : this.nodeToBind;
      return _.filter(
        this.getValidNodes,
        // orphans will have the root of the current canvas also,
        // so the options should not have the current root
        // also hiding goto node type option from dropdown
        gotoNodeOption =>
          (gotoNodeOption.node_id !== nodeToUse.node_id ||
            (!this.addNewNodeBelow && !this.attemptConvertSameNodeToGoto)) &&
          gotoNodeOption.node_type.data.category !== NODE_CATEGORIES.SYSTEM
      );
    },

    keywordConditionsExcludingButtons() {
      return _.filter(
        this.nodeToBind.messaging_node.data.keyword_conditions.data,
        condition => _.get(condition, "keyword_type") !== "Button"
      );
    },

    messageBtnKeywordErrors() {
      const { data: messaging_buttons } = this.allMessagingButtons;
      let obj = {};
      _.forEach(messaging_buttons, button => {
        const {
          keyword,
          keyword_error,
          button_icon,
          button_icon_error
        } = button;
        obj[keyword] = keyword_error;
        obj[button_icon] = button_icon_error;
      });
      return obj;
    },

    queryParams: {
      get: function() {
        return this.stringifyJsonArray(
          this.nodeToBind.messaging_node.data.query_params_json
        );
      },
      set: function(val) {
        this.nodeToBind.messaging_node.data.query_params_json = val;
      }
    },
    smsContent: {
      get: function() {
        return this.nodeToBind.messaging_node.data.sms_content || "";
      },
      set: function(val) {
        this.nodeToBind.messaging_node.data.sms_content = val;
      }
    },
    messageFormat: {
      get() {
        return (
          this.nodeToBind.messaging_node.data.message_format ||
          DEFAULT_MESSAGE_FORMAT
        );
      },
      set(val) {
        // Workaround to trigger vue's reactivity without having to check & set message_format on initialization
        const tmp = _.cloneDeep(this.nodeToBind.messaging_node.data);
        tmp.message_format = val;
        this.$set(this.nodeToBind.messaging_node, "data", tmp);
      }
    },
    messageJson: {
      get: function() {
        return this.nodeToBind.messaging_node.data.message_json || "";
      },
      set: function(val) {
        this.nodeToBind.messaging_node.data.message_json = val;
      }
    },
    incomingJson: {
      get: function() {
        return this.nodeToBind.messaging_node.data.incoming_json || "";
      },
      set: function(val) {
        this.nodeToBind.messaging_node.data.incoming_json = val;
      }
    },
    selectedAction: {
      get: function() {
        let action = _.get(
          this.nodeToBind.messaging_node.data,
          "message_action_option",
          null
        );
        switch (action) {
          case 1:
            return "agentTransfer";
          case 2:
            return "terminate";
          case 0:
          default:
            return "noAction";
        }
      },
      set: function(val) {
        if (val === "noAction") {
          this.nodeToBind.messaging_node.data.message_action_option = 0;
        } else if (val === "agentTransfer") {
          this.nodeToBind.messaging_node.data.message_action_option = 1;
        } else {
          this.nodeToBind.messaging_node.data.message_action_option = 2;
        }
      }
    },
    messageAttachments: {
      get: function() {
        const {
          data: message_attachments
        } = this.nodeToBind.messaging_node.data.messaging_node_attachment;
        return _.isEmpty(message_attachments)
          ? []
          : _.map(message_attachments, attachment => {
              attachment["msg"] = attachment["msg"] || "";
              return attachment;
            });
      },
      set: function(val) {
        if (
          !_.isEqual(this.messageAttachments, val) ||
          _.isEmpty(this.messageAttachments)
        ) {
          this.$set(
            this.nodeToBind.messaging_node.data.messaging_node_attachment,
            "data",
            val
          );
        }
      }
    },
    markdown: {
      get: function() {
        return _.get(
          this.nodeToBind.messaging_node.data,
          "meta_data.data.markdown_content",
          ""
        );
      },
      set: function(val) {
        this.$set(
          this.nodeToBind.messaging_node.data.meta_data.data,
          "markdown_content",
          val
        );
      }
    },

    /**
     * Get the path to the field of the message content for validation
     * @returns { string }
     */
    richTextMessageContentProps: function() {
      return this.isNodeTypeSendSMS
        ? "messaging_node.data.sms_content"
        : "messaging_node.data.meta_data.data.markdown_content";
    },

    /**
     * Rich-text message content handler
     */
    richTextContent: {
      get: function() {
        return this.isNodeTypeSendSMS ? this.smsContent : this.markdown;
      },
      set: function(val) {
        if (this.isNodeTypeSendSMS) {
          this.smsContent = val;
          return;
        }
        this.markdown = val;
      }
    },

    wordRules: {
      get: function() {
        const {
          data: variable_rules
        } = this.nodeToBind.messaging_node.data.variable_rules;
        return _.isEmpty(variable_rules)
          ? []
          : _.filter(
              _.map(variable_rules, rule => {
                rule["msg"] = rule["msg"] || "";
                return rule;
              }),
              rule => {
                return rule.variable_name !== this.arrayVariableName;
              }
            );
      },
      set: function(val) {
        this.newVariableCreated = _.some(
          val,
          variable => variable.variable_id === -1
        );
        if (
          // update only if there is a change in word rules
          !_.isEqual(this.wordRules, val) ||
          _.isEmpty(this.wordRules)
        ) {
          this.$set(
            this.nodeToBind.messaging_node.data.variable_rules,
            "data",
            val
          );
        }
      }
    },
    allKeywordConditions() {
      return _.isEmpty(this.nodeToBind.messaging_node.data.keyword_conditions)
        ? { data: [] }
        : {
            data: _.map(
              this.nodeToBind.messaging_node.data.keyword_conditions.data,

              keywordCondition => {
                keywordCondition["error"] = "";

                if (keywordCondition.node_id === -1) {
                  keywordCondition["error"] = !_.map(
                    this.getValidNodes,
                    "node_name"
                  ).includes(keywordCondition.node_name)
                    ? ""
                    : __("Node ':nodeName' cannot be used here", {
                        nodeName: keywordCondition.node_name
                      });
                }
                if (
                  keywordCondition.node_id === -1 &&
                  keywordCondition.node_name.toLowerCase() === "disconnect"
                ) {
                  keywordCondition["error"] =
                    " '" +
                    `${keywordCondition.node_name}` +
                    "' " +
                    __("cannot be used as node name");
                }

                keywordCondition["keyword_error"] = "";

                const subKeys = ["node_id", "node_name", "keyword"];

                keywordCondition["keyword_error"] = _.some(
                  this.nodeToBind.messaging_node.data.keyword_conditions.data,
                  condition =>
                    !_.isEqual(
                      getSubKeyObject(keywordCondition, subKeys),
                      getSubKeyObject(condition, subKeys)
                    ) &&
                    keywordCondition.keyword.toString().trim() ===
                      condition.keyword.toString().trim()
                )
                  ? __("Keyword conflict")
                  : "";

                if (keywordCondition.keyword_type === "Button") {
                  // to reflect the updated references in button connectors same as
                  // the hidden matching keyword conditions for those button connectors
                  let foundMatchingBtnCondition = _.find(
                    this.allMessagingButtons.data,
                    {
                      keyword: keywordCondition.keyword
                    }
                  );
                  // if the node id is changed for the button connectors
                  if (
                    !_.isEmpty(foundMatchingBtnCondition) &&
                    foundMatchingBtnCondition.node_id !==
                      keywordCondition.node_id
                  ) {
                    keywordCondition.node_id =
                      foundMatchingBtnCondition.node_id;
                    keywordCondition.node_name =
                      foundMatchingBtnCondition.node_name;
                  }
                }

                keywordCondition["msg"] = !keywordCondition.error
                  ? keywordCondition["msg"] || ""
                  : "";

                return keywordCondition;
              }
            )
          };
    },
    keywordConditions: {
      get: function() {
        const { data: keyword_conditions } = this.allKeywordConditions;
        return _.isEmpty(keyword_conditions)
          ? []
          : _.filter(
              keyword_conditions,
              condition =>
                condition.keyword !== "Otherwise" &&
                condition.keyword_type !== "Button"
            );
      },
      set: function(val) {
        const keywordMatchesExceptOtherwiseCondition = this.keywordConditions;
        if (
          // update only if there is a change in keyword conditions excluding the otherwise node
          !_.isEqual(keywordMatchesExceptOtherwiseCondition, val) ||
          _.isEmpty(keywordMatchesExceptOtherwiseCondition)
        ) {
          const otherwiseCondition = this.otherwiseCondition;
          // //get all buttons with branching and add at the end of keyword condition collection
          // const buttonCondition = this.buttonConditions;
          //
          // val = val.concat(buttonCondition);
          this.$set(
            this.nodeToBind.messaging_node.data.keyword_conditions,
            "data",
            val
          );

          if (!_.isEmpty(otherwiseCondition)) {
            this.nodeToBind.messaging_node.data.keyword_conditions.data.push(
              otherwiseCondition
            );
          }
        }
      }
    },
    //gets all messaging buttons with or without connected nodes
    allMessagingButtons() {
      return _.isEmpty(
        this.nodeToBind.messaging_node.data.messaging_node_button
      )
        ? { data: [] }
        : {
            data: _.map(
              this.nodeToBind.messaging_node.data.messaging_node_button.data,

              keywordCondition => {
                keywordCondition["error"] = "";

                if (keywordCondition.node_id === -1) {
                  keywordCondition["error"] = !_.map(
                    this.getValidNodes,
                    "node_name"
                  ).includes(keywordCondition.node_name)
                    ? ""
                    : __("Node ':nodeName' cannot be used here", {
                        nodeName: keywordCondition.node_name
                      });
                }

                keywordCondition["keyword_error"] = "";

                const subKeys = ["node_id", "node_name", "keyword"];
                const subKeysForButtons = [...subKeys, "button_type"];
                // check for message button label conflicts and set error for the conflicting condition
                // if not found then check for keyword conflicts from keyword conditions and set error accordingly
                keywordCondition["keyword_error"] = _.some(
                  this.nodeToBind.messaging_node.data.messaging_node_button
                    .data,
                  condition => {
                    return (
                      !_.isEqual(
                        getSubKeyObject(keywordCondition, subKeysForButtons),
                        getSubKeyObject(condition, subKeysForButtons)
                      ) &&
                      keywordCondition.keyword.toString().trim() ===
                        condition.keyword.toString().trim()
                    );
                  }
                )
                  ? __("Button Label conflict")
                  : _.some(
                      this.keywordConditionsExcludingButtons,
                      condition => {
                        return (
                          !_.isEqual(
                            getSubKeyObject(keywordCondition, subKeys),
                            getSubKeyObject(condition, subKeys)
                          ) &&
                          keywordCondition.keyword.toString().trim() ===
                            condition.keyword.toString().trim()
                        );
                      }
                    )
                  ? __("Keyword conflict")
                  : "";

                keywordCondition["button_icon_error"] =
                  keywordCondition.button_icon &&
                  keywordCondition.button_icon.length > 1000
                    ? __("Icon cannot exceed 1000 characters")
                    : "";

                keywordCondition["msg"] = !keywordCondition.error
                  ? keywordCondition["msg"] || ""
                  : "";

                return keywordCondition;
              }
            )
          };
    },
    messagingButtons: {
      get: function() {
        const { data: message_buttons } = this.allMessagingButtons;
        return _.isEmpty(message_buttons) ? [] : message_buttons;
      },
      set: function(val) {
        const message_buttons = this.messagingButtons;
        if (
          // update only if there is a change in keyword conditions excluding the otherwise node
          !_.isEqual(message_buttons, val) ||
          _.isEmpty(message_buttons)
        ) {
          this.$set(
            this.nodeToBind.messaging_node.data.messaging_node_button,
            "data",
            val
          );
        }
      }
    },
    otherwiseCondition: {
      get: function() {
        const { data: keyword_conditions } = this.allKeywordConditions;
        return _.isEmpty(keyword_conditions)
          ? {}
          : this.findKeywordCondition(
              _.cloneDeep(keyword_conditions),
              "Otherwise"
            );
      },

      set: function(otherwiseCondition) {
        if (
          // append otherwise node to keyword conditions only if otherwise node content changes
          !_.isEqual(this.otherwiseCondition, otherwiseCondition)
        ) {
          let { data: keyword_conditions } = this.allKeywordConditions;
          if (!_.isEmpty(keyword_conditions)) {
            let index = _.findIndex(keyword_conditions, function(condition) {
              return condition.keyword === otherwiseCondition.keyword;
            });
            if (index !== -1) {
              keyword_conditions.splice(index, 1);
            }
          } else {
            keyword_conditions = [];
          }
          keyword_conditions.push(otherwiseCondition);
          this.$set(
            this.nodeToBind.messaging_node.data.keyword_conditions,
            "data",
            keyword_conditions
          );
        }
      }
    },

    /**
     * method to do extra checks to validate form, it cannot be handled by the element UI form validations
     * @returns {boolean}
     */
    formHasErrors() {
      if (this.isNodeTypeConversationSMS || this.isNodeTypeProactiveMessage) {
        const { data: keyword_conditions } = this.allKeywordConditions;
        const { data: messaging_buttons } = this.allMessagingButtons;
        return (
          _.some(keyword_conditions, condition => {
            return condition.error && condition.error.length;
          }) ||
          _.some(keyword_conditions, condition => {
            return condition.keyword_error && condition.keyword_error.length;
          }) ||
          _.some(keyword_conditions, condition => {
            return (
              condition.button_icon_error && condition.button_icon_error.length
            );
          }) ||
          _.some(messaging_buttons, condition => {
            return condition.error && condition.error.length;
          }) ||
          _.some(messaging_buttons, condition => {
            return condition.keyword_error && condition.keyword_error.length;
          }) ||
          _.some(this.wordRules, rule => {
            return rule.error && rule.error.length;
          }) ||
          _.some(this.messageAttachments, attachment => {
            return attachment.error && attachment.error.length;
          })
        );
      }
      return false;
    },

    /**
     * Getter & setter for cards & carousel
     */
    cardModel: {
      get() {
        let val = _.get(
          this.nodeToBind,
          "messaging_node.data.messaging_node_card.data",
          []
        );

        if (Array.isArray(val)) {
          return val;
        }

        return [];
      },

      set(value) {
        if (_.get(this.nodeToBind, "messaging_node") === undefined) {
          this.$set(this.nodeToBind, "messaging_node", {});
        }
        if (_.get(this.nodeToBind, "messaging_node.data") === undefined) {
          this.$set(this.nodeToBind.messaging_node, "data", {});
        }
        if (
          _.get(this.nodeToBind, "messaging_node.data.messaging_node_card") ===
          undefined
        ) {
          this.$set(
            this.nodeToBind.messaging_node.data,
            "messaging_node_card",
            {}
          );
        }
        this.$set(
          this.nodeToBind.messaging_node.data.messaging_node_card,
          "data",
          value
        );
      }
    },

    /**
     * Check if the current node supports post-send action
     */
    showPostSendAction() {
      return this.isNodeTypeReplySMS && this.isTaskTypeChatBot;
    },

    /**
     * Check if the current node supports cards & carousel
     */
    nodeSupportCardsCarousel() {
      return !this.isNodeTypeSendSMS && this.isTaskTypeChatBot;
    },
    shouldShowMessageButtons() {
      return (
        (this.isNodeTypeConversationSMS || this.isNodeTypeProactiveMessage) &&
        this.task_type === "chatbot"
      );
    }
  },
  methods: {
    ...mapActions("smsprofiles", {
      getSmsProfiles: "getSmsProfiles"
    }),

    ...mapActions("smsproviders", {
      getSmsProviders: "getSmsProviders"
    }),

    async toggleCardAndCarouselFeatureFlag() {
      this.nodeLoading = true;
      this.isCardAndCarouselFeatureEnabled = await this.showFeature(
        this.$getConst("STUDIO_CHAT_BOT_CARD_AND_CAROUSEL")
      );
      // if feature flag isn't enabled default to plain text format always
      if (!this.isCardAndCarouselFeatureEnabled) {
        this.messageFormat = DEFAULT_MESSAGE_FORMAT;
      }
      this.nodeLoading = false;
    },

    findKeywordCondition(conditions, keyword) {
      const condition = _.cloneDeep(_.find(conditions, { keyword }));
      return _.isEmpty(condition) ? {} : condition;
    },

    updateInvalidVariableMap(val) {
      return key => {
        this.invalidVariablesMap[key] = val;
      };
    },

    cleanUpNodeToPrepareForSubmit() {
      const nodeToCleanUp = _.cloneDeep(this.nodeToBind);

      if (this.isNodeTypeConversationSMS || this.isNodeTypeProactiveMessage) {
        // cleanup keyword_matches
        let keywordConditions = _.filter(
          filterRowsIfSomeKeyValueIsAbsent(
            _.map(this.allKeywordConditions.data, keywordCondition => ({
              ...keywordCondition,
              keyword: keywordCondition.keyword.toString().trim(),
              node_name: keywordCondition.node_name.toString().trim()
            })),
            "keyword,node_name"
          ),
          condition => {
            const {
              keyword,
              node_id,
              node_name,
              keyword_type,
              condition_option
            } = condition;
            if (keyword_type === "Button") {
              return false;
            }
            return {
              keyword,
              node_id,
              node_name,
              condition_option
            };
          }
        );

        this.$set(
          nodeToCleanUp.messaging_node.data.keyword_conditions,
          "data",
          keywordConditions
        );

        // cleanup messaging buttons
        let messagingButtons = _.map(
          filterRowsIfSomeKeyValueIsAbsent(
            _.map(this.allMessagingButtons.data, messageButton => ({
              ...messageButton,
              keyword: messageButton.keyword.toString().trim(),
              button_icon: messageButton.button_icon.toString().trim(),
              button_url: messageButton.button_url.toString().trim()
            })),
            "keyword"
          ),
          button => {
            const {
              keyword,
              node_id,
              node_name,
              keyword_type,
              button_icon,
              button_url,
              button_type,
              linked_goto_node_id
            } = button;
            return {
              keyword,
              node_id,
              node_name,
              keyword_type,
              button_icon,
              button_url,
              button_type,
              linked_goto_node_id
            };
          }
        );

        this.$set(
          nodeToCleanUp.messaging_node.data.messaging_node_button,
          "data",
          messagingButtons
        );

        // cleanup extract_words
        const variableRules = _.map(
          filterRowsIfEveryKeyValueIsAbsent(
            _.map(this.wordRules, wordRule => ({
              ...wordRule,
              rule_value: wordRule.rule_value.toString().trim(),
              variable_name: wordRule.variable_name.toString().trim()
            })),
            "rule_value,variable_name"
          ),
          rule => {
            const {
              variable_id,
              rule_value,
              variable_name,
              default_value
            } = rule;
            return { variable_id, rule_value, variable_name, default_value };
          }
        );

        let arrayRule = _.find(
          this.wordRules,
          rule => rule.variable_name === this.arrayVariableName
        );
        let { variable_name, variable_id } = this.arrayVariable;
        if (!arrayRule) {
          arrayRule = {
            rule_value: "root",
            default_value:
              nodeToCleanUp.messaging_node.data.incoming_json === ""
                ? ""
                : JSON.stringify({
                    ws_response_data: JSON.parse(
                      nodeToCleanUp.messaging_node.data.incoming_json
                    )
                  }),
            variable_name,
            variable_id,
            array_variable: true,
            variable_type: "array"
          };
          variableRules.push(arrayRule);
        }

        this.$set(
          nodeToCleanUp.messaging_node.data.variable_rules,
          "data",
          variableRules
        );
      } else if (
        this.isNodeTypeSendSMS &&
        (this.isTaskTypeVoice || this.isTaskTypeChatBot)
      ) {
        let newVar = _.find(
          nodeToCleanUp.messaging_node.data.variable_rules.data,
          { variable_id: -1 }
        );
        if (newVar !== undefined) {
          this.newVariableCreated = true;
        }
      }

      //cleanup message attachments
      const messageAttachments = _.map(
        filterRowsIfEveryKeyValueIsAbsent(
          _.map(this.messageAttachments, messageAttachment => ({
            ...messageAttachment,
            attachment_type:
              messageAttachment.attachment_type == undefined
                ? ""
                : messageAttachment.attachment_type.toString().trim(),
            file_url: messageAttachment.file_url.toString().trim(),
            file_id: messageAttachment.file_id,
            file_name: messageAttachment.file_name
          })),
          "attachment_type,file_url,file_id,file_name"
        ),
        attachment => {
          const {
            attachment_type,
            file_url,
            file_id,
            file_name,
            validate_audio
          } = attachment;
          return {
            attachment_type,
            file_url,
            file_id,
            file_name,
            validate_audio
          };
        }
      )
        // Filter all row that contains empty attachment type
        .filter(row => {
          const str = row.attachment_type;
          const isEmpty = !str || str.length === 0;
          return !isEmpty;
        });

      this.$set(
        nodeToCleanUp.messaging_node.data.messaging_node_attachment,
        "data",
        messageAttachments
      );

      // cleanup query_params_json
      nodeToCleanUp.messaging_node.data.query_params_json = JSON.stringify(
        filterRowsIfSomeKeyValueIsAbsent(
          nodeToCleanUp.messaging_node.data.query_params_json,
          "key"
        )
      );

      // Cleanup card buttons
      const messagingNodeCard = _.get(
        nodeToCleanUp,
        "messaging_node.data.messaging_node_card.data",
        []
      );
      _.set(
        nodeToCleanUp,
        "messaging_node.data.messaging_node_card.data",
        messagingNodeCard.map(card => {
          card.messaging_node_card_button = card.messaging_node_card_button.filter(
            button => {
              return (
                !!button.card_button_goto_node_name && !!button.card_button_text
              );
            }
          );
          return card;
        })
      );
      return nodeToCleanUp;
    },

    cleanUpNode() {
      if (!this.formHasErrors) {
        this.nodeToBind = this.cleanUpNodeToPrepareForSubmit();
        this.createOrEditNode();
      } else {
        this.toggleNodeSubmit(false);
      }
    },

    handleMessageBtnRemoved(button_id) {
      _.remove(
        this.nodeToBind.messaging_node.data.keyword_conditions.data,
        obj => obj.button_id === button_id
      );
    },

    smsProfileChange(smsProfileId) {
      if (smsProfileId) {
        this.smsProfileReturnValue.splice(0, this.smsProfileReturnValue.length);
        this.selectedSmsProfile = _.cloneDeep(this.getSmsProfile(smsProfileId));
        this.selectedSmsProvider = _.cloneDeep(
          this.getSmsProvider(this.selectedSmsProfile.sms_provider_id)
        );

        if (
          this.selectedSmsProfile &&
          this.selectedSmsProvider &&
          this.selectedSmsProvider.json_data
        ) {
          if (this.selectedSmsProvider.json_data.response) {
            let responseParameters = this.selectedSmsProvider.json_data
              .response;
            for (let i in responseParameters) {
              this.smsProfileReturnValue.push({
                variable_id: "",
                rule_value: responseParameters[i].key
              });
            }
          }
        }
      }
      this.componentKey++;
    },

    handleUpdateSmsProfileReturnValue(value) {
      this.smsProfileReturnValue = _.cloneDeep(value);
      let filteredVariableRulesData = filterRowsIfSomeKeyValueIsAbsent(
        [...value],
        "variable_name"
      );
      this.$set(
        this.nodeToBind.messaging_node.data.variable_rules,
        "data",
        filteredVariableRulesData
      );
    },

    initializeSmsProfileReturnValues() {
      this.smsProfileChange(this.nodeToBind.messaging_node.data.sms_profile_id);

      if (
        this.nodeToBind.messaging_node.data.variable_rules &&
        !_.isEmpty(this.nodeToBind.messaging_node.data.variable_rules.data)
      ) {
        const variableRules = _.cloneDeep(
          this.nodeToBind.messaging_node.data.variable_rules.data
        );
        const assignedVariableRuleData = _.remove(variableRules, function(
          rule
        ) {
          return rule.rule_value !== "root" && _.isEmpty(rule.default_value);
        });

        const returnValue = _.merge(
          this.smsProfileReturnValue,
          assignedVariableRuleData
        );
        this.handleUpdateSmsProfileReturnValue(returnValue);
        this.componentKey++;
      } else {
        this.$set(this.nodeToBind.messaging_node.data, "variable_rules", {});
        this.$set(
          this.nodeToBind.messaging_node.data.variable_rules,
          "data",
          []
        );
      }
    },

    handleCheckedPostSendAction(value) {
      if (value === false) {
        this.selectedAction = "noAction";
      }
    },

    isPostSendingActionChecked() {
      if (_.isEmpty(this.selectedAction)) {
        this.checkedPostSendAction = false;
      }
      this.checkedPostSendAction = this.selectedAction !== "noAction";
    }
  },
  created() {
    this.getSmsProviders();
    this.getSmsProfiles();

    this.toggleCardAndCarouselFeatureFlag();

    if (!this.nodeToBind.node_id || _.isEmpty(this.nodeToBind.messaging_node)) {
      this.$set(this.nodeToBind, "messaging_node", {});
      this.$set(this.nodeToBind.messaging_node, "data", {});
      this.$set(this.nodeToBind.messaging_node.data, "sms_content", "");
      this.$set(this.nodeToBind.messaging_node.data, "message_json", "");
      this.$set(this.nodeToBind.messaging_node.data, "incoming_json", "");
      this.$set(this.nodeToBind.messaging_node.data, "web_hook_url", "");
      this.$set(this.nodeToBind.messaging_node.data, "phone_number", "");
      this.$set(this.nodeToBind.messaging_node.data, "sms_profile_id", null);
      this.$set(
        this.nodeToBind.messaging_node.data,
        "message_action_option",
        0
      );

      if (
        _.isEmpty(this.nodeToBind.messaging_node.data.messaging_node_attachment)
      ) {
        this.$set(
          this.nodeToBind.messaging_node.data,
          "messaging_node_attachment",
          {}
        );
      }

      if (
        _.isEmpty(this.nodeToBind.messaging_node.data.messaging_node_button)
      ) {
        this.$set(
          this.nodeToBind.messaging_node.data,
          "messaging_node_button",
          {}
        );
      }

      if (this.isTaskTypeVoice || this.isTaskTypeChatBot) {
        this.$set(this.nodeToBind.messaging_node.data, "variable_rules", {});
        this.$set(
          this.nodeToBind.messaging_node.data.variable_rules,
          "data",
          []
        );
      }
    } else {
      if (this.displaySmsProfile) {
        if (!this.nodeToBind.messaging_node.data.sms_profile_id) {
          this.$set(
            this.nodeToBind.messaging_node.data,
            "sms_profile_id",
            null
          );
        }
        this.initializeSmsProfileReturnValues();
      }
    }

    if (
      _.isEmpty(this.nodeToBind.messaging_node.data.meta_data) ||
      _.isEmpty(this.nodeToBind.messaging_node.data.meta_data.data)
    ) {
      this.$set(this.nodeToBind.messaging_node.data, "meta_data", {});
      this.$set(this.nodeToBind.messaging_node.data.meta_data, "data", {
        markdown_content: ""
      });
    }

    this.$set(
      this.nodeToBind.messaging_node.data,
      "query_params_json",
      this.queryParams
    );
  }
};
</script>

<style scoped lang="scss">
@import "~@/styles/node-palette.scss";
@import "~@/styles/node_common.scss";

.tabs ::v-deep .el-tabs__item.is-active {
  color: $--color-messaging_conversation-node;
}

.tabs ::v-deep .el-tabs__item:hover {
  color: $--color-messaging_conversation-node;
}

.tabs ::v-deep .el-tabs__active-bar {
  background-color: $--color-messaging_conversation-node;
}

/*.tabs ::v-deep .el-tabs__nav-wrap::after {*/
/*  background-color: white;*/
/*}*/

.text-input ::v-deep textarea {
  resize: none;
}

.tabPane {
  max-height: 52vh;
  padding-right: 30px;
}

.msg-counter {
  position: absolute;
  right: 0;
  bottom: 10px;
}

.tabs ::v-deep .el-scrollbar__bar {
  opacity: 1;
}
</style>
